<template>
  <div class="leading-normal" v-if="render">
    <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:StatusMarital') }}
      </span>
      <SelectField
          class="w-300"
          v-model="userDataEdit.statusMarital.id"
          :placeholder="''"
          :options="statusesMarital"
      />
    </div>
    <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:StatusParental') }}
      </span>
      <SelectField
          class="w-300"
          v-model="userDataEdit.statusParental.id"
          :placeholder="''"
          :options="statusesParental"
      />
    </div>
  </div>
</template>

<script>
import SelectField from "@/utils/crud/components/field-types/Select";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "StatusForm",
  components: {SelectField},
  props: {
    userData: Object,
    sendRequest: Boolean,
  },
  data() {
    return {
      userDataEdit: null,
      render: false,
    }
  },
  watch: {
    userDataEdit() {
      this.render = true;
    },
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        this.sendEditRequest({
          status_marital_id: this.userDataEdit.statusMarital.id,
          status_parental_id: this.userDataEdit.statusParental.id,
        })
      }
    }
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchMaritalData', 'fetchParentalData'])
  },
  computed: {
    ...mapGetters('dictionaries', ['statusesMarital', 'statusesParental'])
  },
  mounted() {
    this.fetchParentalData();
    this.fetchMaritalData();
    this.userDataEdit = structuredClone(this.userData);
  }
}
</script>
