<template>
  <div v-if="render">
    <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:ApproachDrinking') }}
      </span>
      <SelectField
          class="w-300"
          v-model="userDataEdit.approachDrinking.id"
          :placeholder="''"
          text-attr="slugTrans"
          :options="stimulantsAndPetsData.approachDrinkings"
      />
    </div>
    <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:ApproachSmoking') }}
      </span>
      <SelectField
          class="w-300"
          v-model="userDataEdit.approachSmoking.id"
          :placeholder="''"
          text-attr="slugTrans"
          :options="stimulantsAndPetsData.approachSmokings"
      />
    </div>
	  <div class="flex flex-row items-center justify-between mb-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:ApproachDrugging') }}
      </span>
		  <SelectField
				  class="w-300"
				  v-model="userDataEdit.approachDrugging.id"
				  :placeholder="''"
				  text-attr="slugTrans"
				  :options="stimulantsAndPetsData.approachDruggings"
		  />
	  </div>
  </div>
</template>

<script>
import SelectField from "@/utils/crud/components/field-types/Select";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  name: "StimulantForm",
  components: {SelectField},
  props: {
    userData: Object,
    sendRequest: Boolean,
  },
  data() {
    return {
      userDataEdit: null,
      render: false,
    }
  },
  watch: {
    userDataEdit() {
      this.render = true;
    },
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        this.sendEditRequest({
          approach_drinking_id: this.userDataEdit.approachDrinking.id,
          approach_smoking_id: this.userDataEdit.approachSmoking.id,
          approach_drugging_id: this.userDataEdit.approachDrugging.id,
        })
      }
    }
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchMaritalData', 'fetchParentalData'])
  },
  computed: {
    ...mapGetters('dictionaries', ['stimulantsAndPetsData'])
  },
  mounted() {
    this.userDataEdit = structuredClone(this.userData);
  }
}
</script>
