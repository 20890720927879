<template>
  <div class="leading-normal">
    <!-- LARGE SCREEN -->
    <template v-if="!isMobile">
      <div class="flex flex-row justify-end lg:-mt-6 mb-3 px-3" v-if="!showHint">
        <Button
          variant="link"
          :disabled="isDisabledResetSettings"
          class="text-s inline-flex hover:opacity-50 cursor-pointer items-center"
          @clicked="resetExperiences"
        >
          <base-icon class="mr-2" name="reset"></base-icon>
          {{ trans('BUTTON:ResetSettings') }}
        </Button>
      </div>
      <div>
        <div class="my-3 lg:pl-3" v-if="showHint">
          <Hint variant="Info" class="lg:mr-3">
            {{ trans('LABEL:ExperienceComment') }}
          </Hint>
        </div>
      </div>
      <div class="flex flex-row justify-end mb-3 px-3" v-if="showHint">
        <Button
          variant="link"
          :disabled="isDisabledResetSettings"
          class="text-s inline-flex hover:opacity-50 cursor-pointer items-center"
          @clicked="resetExperiences"
        >
          <base-icon class="mr-2" name="reset"></base-icon>
          {{ trans('BUTTON:ResetSettings') }}
        </Button>
      </div>
      <div class="grid grid-cols-6 grid-rows-12">
        <!-- Experience header -->
        <div class="col-span-full row-start-0 flex flex-col">
          <div class="flex flex-row items-center grid grid-cols-8 rounded px-3">
            <div class="col-span-4">
            </div>
            <div
              class="text-s text-center leading-normal  font-semibold uppercase pb-2
                border-b border-grayedHR"
              :class="[`col-start-${5+index}`, rate.color]"
              v-for="(rate, index) in ratesHeaders" :key="index"
            >
              <VTooltip>
                <base-icon :name="rate.icon" class="h-6 w-6"></base-icon>
                <template #popper>
                  {{ trans(rate.title) }}
                </template>
              </VTooltip>
            </div>
          </div>
        </div>
        <!-- Experience rows -->
        <div class="col-span-full row-start-2 flex flex-col gap-0.5 my-1">
          <div class="flex flex-row items-center grid grid-cols-8 hover:bg-grayedLight hover:cursor-pointer rounded px-3"
            v-for="(exp, index) in experiencesData"
            :key="index"
          >
            <div class="flex items-center col-span-4 py-1 justify-start ">
              <VTooltip>
                <span class="hover:cursor-default">
                  {{ trans(exp.name) }}
                </span>
                <template #popper>
                  {{ trans(exp.description) }}
                </template>
              </VTooltip>
            </div>

            <t-radio v-for="rate in ratesData"
              :key="rate.id"
              :variant="rate.slug"
              :name="`rated_user_experience_${exp.id}`"
              v-model="newExperiences[exp.id]"
              :value="rate.id"
              :checked="isExperienceChecked(rate.slug, exp.id)"
            />
          </div>
        </div>
        <!-- Bottom line -->
        <div class="col-span-full row-start-0 flex flex-col px-3">
          <div class="flex flex-row items-center grid grid-cols-8 rounded">
            <div class="col-span-4">
            </div>
            <div class="col-span-4 leading-normal  border-t border-grayedHR">
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- MOBILE -->
    <template v-else>
      <div class="my-3 px-1.5" v-if="showHint">
        <Hint variant="Info">
          {{ trans('LABEL:ExperienceComment') }}
        </Hint>
      </div>
      <div class="flex flex-row justify-end mb-3 px-1.5">
        <Button
          variant="link"
          class="text-s inline-flex hover:opacity-50 cursor-pointer items-center"
          :disabled="isDisabledResetSettings"
          @clicked="resetExperiences"
        >
          <base-icon class="mr-2" name="reset">
          </base-icon>
          {{ trans('BUTTON:ResetSettings') }}
        </Button>
      </div>
      <FieldWrapper row v-for="(exp, index) in experiencesData"
        :key="index"
        class="flex flex-row items-center justify-between gap-3 mb-3 gap-x-3 px-1.5"
      >
        <div class="w-170 text-base">
          {{ trans(exp.nameOrg) }}
        </div>
        <SelectField
          class="w-300"
          :options="ratesData"
          value-attr="id"
          experienceSelect
          v-model="newExperiences[exp.id]"
        >
        </SelectField>
      </FieldWrapper>
    </template>
  </div>
</template>

<script>
import TextField from "@/utils/crud/components/field-types/Text";
import {mapActions, mapGetters, mapMutations} from "vuex";
import SelectField from "@/utils/crud/components/field-types/Select";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import Button from "@/utils/crud/components/Button";
import BaseIcon from "@/utils/icons/BaseIcon";
import Hint from "@/views/content/components/Hint"

export default {
  name: "ExperiencesForm",
  components: {BaseIcon, Button, FieldWrapper, Hint, SelectField, TextField},
  props: {
    userData: Object,
    sendRequest: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      newExperiences: {},
      render: false,
      showHint: false,
      curiousRate: null,
      knownRate: null,
      nowayRate: null,
      isDisabledResetSettings: false,
      ratesHeaders: [
        {
          title: 'PROPERTY_EXPERIENCE_RATE:1.Known',
          icon: 'emojiHeart',
          color: 'text-badgeSuccess',
        },
        {
          title: 'PROPERTY_EXPERIENCE_RATE:2.Curious',
          icon: 'emojiWink',
          color: 'text-badgeInfo',
        },
        {
          title: 'PROPERTY_EXPERIENCE_RATE:3.NoWay',
          icon: 'emojiAngry',
          color: 'text-badgeError',
        },
        {
          title: 'PROPERTY_EXPERIENCE_RATE:4.Neutral',
          icon: 'emojiNeutral',
          color: 'text-grayed',
        },
      ],
    }
  },
  watch: {
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        this.sendEditRequest({
          rated_user_experiences: this.newExperiences,
        })
      }
    },

    newExperiences: {
      deep: true,
      handler(val) {
        this.showHint = !Object.values(val).some(el => Number(el) === this.curiousRate.id || Number(el) === this.knownRate.id);
        this.isDisabledResetSettings = !Object.values(val).some(el => Number(el) === this.curiousRate.id || Number(el) === this.knownRate.id || Number(el) === this.nowayRate.id);
      }
    },
  },
  computed: {
    ...mapGetters('dictionaries', ['experiencesData', 'ratesData'])
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchExperiencesData', 'fetchRatesData']),
    isExperienceChecked(type, experience) {
      return this.userData.experiences[type].some(el => {
        return experience === el.id;
      })
    },
    setNewExperiences() {
      this.userData.experiences['known'].filter(el => {
        this.$set(this.newExperiences, el.id, this.ratesData.find(el => el.slug === 'known').id)
      });
      this.userData.experiences['curious'].filter(el => {
        this.$set(this.newExperiences, el.id, this.ratesData.find(el => el.slug === 'curious').id)
      });
      this.userData.experiences['noway'].filter(el => {
        this.$set(this.newExperiences, el.id, this.ratesData.find(el => el.slug === 'noway').id)
      });
      this.userData.experiences['neutral'].filter(el => {
        this.$set(this.newExperiences, el.id, this.ratesData.find(el => el.slug === 'neutral').id)
      });

      if(this.userData.experiences['known'].length === 0 && this.userData.experiences['curious'].length === 0 && this.userData.experiences['noway'].length === 0 && this.userData.experiences['neutral'].length === 0) {
        this.isDisabledResetSettings = true;
        this.experiencesData.filter(el => {
          this.$set(this.newExperiences, el.id, this.ratesData.find(el => el.slug === 'neutral').id)
        })
      }
    },
    resetExperiences() {
      this.experiencesData.filter(el => {
        this.$set(this.newExperiences, el.id, this.ratesData.find(el => el.slug === 'neutral').id)
        this.isDisabledResetSettings = true;
      })
    },
  },
  mounted() {
    this.curiousRate = this.ratesData.find(el => el.slug === 'curious');
    this.knownRate = this.ratesData.find(el => el.slug === 'known');
    this.nowayRate = this.ratesData.find(el => el.slug === 'noway');
    this.setNewExperiences();
  }
}
</script>
