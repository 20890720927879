<template>
  <div class="leading-normal" v-if="render">
    <div class="flex flex-row mb-3 items-center justify-between gap-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:GenderFormal') }}
      </span>
      <TextField
        variant="basicInfo"
        class="w-300"
        name="name"
        field="name"
        disabled
        v-model="userDataEdit.genderFormal.desc"
      />
    </div>
    <div class="flex flex-row mb-3 items-center justify-between gap-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:GenderDeclared') }}
      </span>
      <TextField
        variant="basicInfo"
        class="w-300"
        name="name"
        field="name"
        disabled
        v-model="userDataEdit.gender.desc"
      />
    </div>
    <div class="flex flex-row items-center justify-between mb-3 gap-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:YearOfBirth') }}
      </span>
      <TextField
        variant="basicInfo"
        class="w-300"
        name="name"
        field="name"
        disabled
        v-model="userDataEdit.yearOfBirth"
      />
    </div>
    <div class="flex flex-col mb-3">
      <Hint variant="Info" class="mb-3">
        {{ trans('LABEL:GenderOrYearChange') }}
      </Hint>
      <div class="w-full text-s text-right">
        <Button
          variant="link"
          class="text-redAccentLight"
          @clicked="openContactModal(true)"
          :text="trans('LABEL:ContactAdmin')"
        />
      </div>
    </div>
    <div class="flex flex-row items-center justify-between mb-3 gap-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:DisplayedName') }}
      </span>
      <TextField
        class="w-300"
        name="name"
        field="name"
        v-model="userDataEdit.name"
      />
    </div>
    <div class="flex flex-row items-center justify-between mb-3 gap-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:Orientation') }}
      </span>
      <SelectField
        class="w-300"
        :options="orientationData"
        v-model="userDataEdit.orientation.id"
        :placeholder="''"
      />
    </div>
	  <div class="flex flex-row items-center justify-between mb-3 gap-3">
      <span class="w-170 text-base">
      </span>
		  <CheckboxField
				  class="w-300"
				  v-model="userDataEdit.acceptBi"
				  :label="trans('LABEL:AcceptBi')"
		  />
    </div>
    <div class="flex flex-row items-center justify-between mb-3 gap-3">
      <span class="w-170 text-base">
        {{ trans('LABEL:Preference') }}
      </span>
      <SelectField
        class="w-300"
        :options="preferenceData"
        v-model="userDataEdit.preference.id"
        :placeholder="''"
      />
    </div>
	  <div class="flex flex-row items-center justify-between gap-3">
      <span class="w-170 text-base">
        {{ trans('') }}
      </span>
		  <CheckboxField
        class="w-300"
        v-model="userDataEdit.isCoupleProfile"
        :label="trans('LABEL:IsCoupleProfile')"
		  />
	  </div>
    <Modal
      v-if="isShowContactModal"
      :showModal="isShowContactModal"
      :hide-footer="true"
      @clicked="openContactModal(false)"
      :header="trans('MODAL_TITLE:ContactForm')"
    >
      <template v-slot:content>
        <ContactForm type="CHANGE"/>
      </template>
    </Modal>
  </div>
</template>

<script>
import BaseIcon from "@/utils/icons/BaseIcon";
import Button from "@/utils/crud/components/Button";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox.vue";
import ContactForm from "@/views/content/components/ContactForm";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import Modal from "@/utils/modal/Modal";
import SelectField from "@/utils/crud/components/field-types/Select";
import TextField from "@/utils/crud/components/field-types/Text";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Hint from "@/views/content/components/Hint"

export default {
  name: "BasicInformationForm",
  components: {
    CheckboxField, ContactForm, Modal, Button, FieldWrapper, SelectField, TextField, BaseIcon, Hint},
  props: {
    userData: Object,
    sendRequest: Boolean,
  },
  data() {
    return {
      userDataEdit: {},
      name: null,
      render: false,
      isNameFormShow: false,
      isShowContactModal: false,
    }
  },
  watch: {
    userDataEdit() {
      this.render = true;
    },
    sendRequest(val) {
      if(val) {
        this.setSendRequestStatus(false);
        this.sendEditRequest({
          preference_id: this.userDataEdit.preference.id,
          orientation_id: this.userDataEdit.orientation.id,
          name: this.userDataEdit.name,
	        is_couple_profile: !!this.userDataEdit.isCoupleProfile,
	        accept_bi: !!this.userDataEdit.acceptBi
        })
      }
    },
  },
  computed: {
    ...mapGetters('dictionaries', ['orientationData', 'preferenceData'])
  },
  methods: {
    ...mapMutations('profile', ['setSendRequestStatus']),
    ...mapActions('profile', ['sendEditRequest']),
    ...mapActions('dictionaries', ['fetchOrientationData', 'fetchPreferenceData']),
    openContactModal(value) {
      this.isShowContactModal = value;
    }
  },

  mounted() {
    this.userDataEdit = structuredClone(this.userData);
  }
}
</script>
