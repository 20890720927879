// converting to string is for SelectField purpose
const prepareOptionsArray = function(min, max, step, reverse = false, makeObject = false, suffix = null) {
    let arrayLength = Math.floor(((max - min) / step)) + 1;
    let array;
    const objectFromArray = {};

    if(reverse) {
        array = [...Array(arrayLength).keys()].map(x => (max - (x * step)).toString());
    } else {
        array = [...Array(arrayLength).keys()].map(x => ((x * step) + min).toString());
    }

    if(makeObject) {
        for (const key of array) {
            objectFromArray[key] = `${key} ${suffix}`
        }

        return objectFromArray;
    }

    return array;
}

export default {prepareOptionsArray};